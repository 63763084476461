import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PublicPageService } from 'src/app/services';

@Injectable()
export class SidebarService {
	activeClass = '';

	peopleLoaded = {
		'people-directory': false,
		'open-door': false,
		'terra-fellows': false,
		experts: false,
	};

	constructor(
		private http: HttpClient,
		private publicPageService: PublicPageService,
	) {}

	public loadSideBar() {
		if (!this.publicPageService.isAppPage) {
			return of([]);
		} else {
			return this.http.get<any>(`${environment.url.cohort}/student/cohorts/v3`);
		}
	}
}
