import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { Observable } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';
import { NgrxService } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Injectable()
export class AssignmentService {
	constructor(private http: HttpClient, private ngrxService: NgrxService) {}

	loadProfile() {
		return this.http.get<any>(environment.url.profile + '/user');
	}

	createAssignment(
		assignmentId: string,
		url: string,
		createPad: boolean = false,
	) {
		return this.http.post<any>(`${environment.url.cohort}/createAssignment`, {
			assignmentId,
			url,
			createPad,
		});
	}

	deleteAssignment(assignmentId: string) {
		return this.http.delete<any>(
			`${environment.url.cohort}/student/assignment/${assignmentId}`,
		);
	}

	public assignmentMemberAdd(
		id: string,
		assignmentGroupId: number,
		members: string[],
	): Observable<any> {
		if (assignmentGroupId === 0) {
			return this.http.post(`${environment.url.cohort}/assignmentGroup`, {
				id,
				members,
			});
		} else {
			return this.http.post(`${environment.url.cohort}/assignmentGroup`, {
				id,
				assignmentGroupId,
				members,
			});
		}
	}

	serviceName = 'assignment';
	getAssignmentDetailInit(cohortId: number, assignmentId: string) {
		this.ngrxService.init(this.serviceName, cohortId, assignmentId);
	}

	getAssignmentDetailAPI(cohortId: number, assignmentId: string) {
		return this.ngrxService
			.loadInitAPI(this.serviceName, cohortId, assignmentId)
			.pipe(
				concatMap(() => this.getAssignmentDetailReload(cohortId, assignmentId)),
			);
	}

	getAssignmentDetailReload(cohortId, assignmentId) {
		return this.http
			.get(`${environment.url.cohort}/student/assignment/${assignmentId}/v3`)
			.pipe(
				map((data: any) => {
					if (data.message === 'no group found.') {
						return {
							assignmentGroupId: 0,
							members: [],
							id: assignmentId,
							url: '',
							groupPadId: '',
							assignmentTeam: false,
							assignmentType: data.assignmentType,
						};
					} else {
						return data;
					}
				}),
			)
			.pipe(
				tap((data: any) =>
					this.ngrxService.loadInitData(
						this.serviceName,
						cohortId,
						assignmentId,
						[data],
					),
				),
			);
	}

	getAssignmentDetailData(cohortId: number, assignmentId: string): any {
		return this.ngrxService.loadData(this.serviceName, cohortId, assignmentId);
	}

	getAssignmentDetailReset(cohortId: number, assignmentId: string) {
		this.ngrxService.resetState(this.serviceName, cohortId, assignmentId);
	}
}
