/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/browser';
import { EmailAuthProvider } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { RudderStack } from '../rudderstack';
import { error } from './errors';
import { SignUpService } from './sign-up/sign-up.service';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	public error: string[] = [];
	public email: any;
	// public isB2B: boolean = false;
	// public B2BLogo: string;

	public isB2B = new BehaviorSubject<boolean>(null);
	public isB2B$ = this.isB2B as Observable<boolean>;

	public B2BLogo = new BehaviorSubject<string>(null);
	public B2BLogo$ = this.B2BLogo as Observable<string>;

	constructor(
		private authFire: AngularFireAuth,
		private signUpService: SignUpService,
		private router: Router,
		public ngZone: NgZone,
		private cookieService: CookieService,
		private rudderStack: RudderStack,
		public afStore: AngularFirestore,
	) {}

	public loginWithCustomToken(token) {
		this.authFire
			.setPersistence('local')
			.then(() => this.authFire.signInWithCustomToken(token))
			.then(res => {
				this.fetchAndSetToken(res.user);
			})
			.catch(e => this.showError(e));
	}

	public removeToastMsg(str) {
		this.error = this.error.filter(err => err !== str);
	}

	public addToastMsg(str, timeout = 3) {
		this.error.push(str);

		setTimeout(() => {
			this.removeToastMsg(str);
		}, timeout * 1000);
	}

	public signIn(email: string, password: string) {
		return this.authFire
			.signInWithEmailAndPassword(email, password)
			.then(u2 => {
				this.log(100, 'login', 'password');

				this.fetchAndSetToken(u2.user);

				this.addToastMsg('Redirecting to home page!', 1);
			})
			.catch(e => this.showError(e));
	}

	public googleSignIn() {
		this.authFire
			.signInWithPopup(new firebase.auth.GoogleAuthProvider())
			.then((res: any) => {
				this.afStore
					.doc(`users/${res.user.uid}`)
					.get()
					.subscribe(async (data: any) => {
						if (data.data() === undefined) {
							await this.signUpService.setUserData(
								res.user,
								res.user.name,
								true,
							);
						}

						this.fetchAndSetToken(res.user);

						this.log(100, 'login', 'google');
					});
			})
			.catch(e => this.showError(e));
	}

	public afterLogin() {
		const redirectTo = localStorage.getItem('redirectTo');
		this.rudderStack.identify(firebase.auth().currentUser?.email, null);

		Sentry.setUser({ email: firebase.auth().currentUser?.email });
		if (redirectTo) {
			localStorage.removeItem('redirectTo');
			if (redirectTo === '/auth/login') {
				this.router.navigate(['app']);
			} else {
				window.open(redirectTo, '_self');
			}
		} else {
			this.router.navigate(['app']);
		}
	}

	public signUp(name, email, password, subscribedToNewsletter) {
		return this.signUpService
			.init(name, email, password, subscribedToNewsletter)
			.then(() => {
				this.log(-1, 'signup', 'password');

				this.afterLogin();
			})
			.catch(e => this.showError(e));
	}

	public googleSignUp(subscribedToNewsletter) {
		return this.signUpService
			.googleInit(subscribedToNewsletter)
			.then(() => {
				this.log(-1, 'signup', 'google');

				this.afterLogin();
			})
			.catch(e => this.showError(e));
	}

	public signOut(bool: boolean = true) {
		this.authFire.signOut().then(() => {
			window.location.href = environment.url.onboarding + 'auth/logout';
		});
	}

	public redirectAuth(
		url: 'forgot-password' | 'forgot-email' | 'sign-up' | 'login',
	) {
		this.router.navigateByUrl(`/auth/${url}`, { skipLocationChange: true });
	}

	public resetPassword(email, skipMsg = false) {
		return this.authFire
			.sendPasswordResetEmail(email)
			.then(() => {
				this.log(-1, 'reset', 'password');

				if (skipMsg === false) {
					this.addToastMsg(
						'We have sent a link to ' + email + ' to reset your password.',
					);

					this.router.navigate(['/auth/login']);
				}
			})
			.catch(e => this.showError(e));
	}

	private showError(e: any) {
		const index = error.findIndex(x => x.code === e.code);

		if (index !== -1) {
			const err = error[index];

			if (err.logout) {
				this.signOut();
			}
			this.addToastMsg(err.message);
		} else {
			this.addToastMsg('Oops, something went wrong!');
		}

		throw new Error('error');
	}

	public fetchAndSetToken(user3: any) {
		this.fetchAndSetTokenAPI(user3.uid).then(data => {
			if (environment.earthToken) {
				this.setCookie('earth_token', data.token);
			} else {
				this.setCookie('welcome_user', user3.uid);
				this.setCookie('earth_token', data.token);
			}
			this.setCookie('app_user', user3.uid);

			this.afterLogin();
		});
	}

	public fetchAndSetTokenAPI(uid: string) {
		return fetch(environment.url.firebaseToken + `?uid=${uid}`, {
			method: 'GET',
		}).then(response => response.json());
	}

	public setCookie(name: string, val: string) {
		const date = new Date();
		const value = val;

		// Set it expire in 7 days
		date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);

		// Set it

		if (environment.earthToken) {
			this.cookieService.set(name, value, date, '/', '.terra.do');
		} else {
			this.cookieService.set(name, value, date);
		}
		/*this.window.document.cookie =
			name +
			'=' +
			value +
			';expires=' +
			date.toUTCString() +
			';path=/;domain=.terra.do;';*/
	}

	public log(event, type, provider) {
		this.rudderStack.log({
			event,
			type,
			provider,
		});
	}

	public setB2BLogo(flag) {
		if (this.cookieService.get('B2BLogo')) {
			this.isB2B.next(true);
			this.B2BLogo.next(this.cookieService.get('B2BLogo'));
		} else {
			// this.cookieService.set(
			// 	'B2BLogo',
			// 	'https://res.cloudinary.com/terra-do/image/upload/v1676618766/B2B%20Images/Group_1691_wgih0t.svg',
			// );
			// this.isB2B.next(true);
			// this.B2BLogo.next(
			// 	'https://res.cloudinary.com/terra-do/image/upload/v1676618766/B2B%20Images/Group_1691_wgih0t.svg',
			// );

			fetch(environment.url.profile + `/b2b/company/${flag}/logo`, {
				method: 'GET',
			})
				.then(response => response.json())
				.then(data => {
					this.cookieService.set('B2BLogo', data.logo);
					this.isB2B.next(true);
					this.B2BLogo.next(data.logo);
				});
		}
	}

	public changePassword(currentPassword, newPassword) {
		return new Promise(async (resolve, reject) => {
			const credential = EmailAuthProvider.credential(
				firebase.auth()?.currentUser?.email,
				currentPassword,
			);

			const result = await firebase
				.auth()
				.currentUser?.reauthenticateWithCredential(credential)
				.catch(e => {
					this.rudderStack.log({
						type: 'change_password_error_impression',
						message: e.code,
					});

					if (e.code === 'auth/wrong-password') {
						resolve({ status: false, message: e.code });
					} else {
						resolve(false);
						this.showError(e);
					}
				});

			return firebase
				.auth()
				.currentUser?.updatePassword(newPassword)
				.then(() => {
					resolve({ status: true });
				})
				.catch(e => {
					this.rudderStack.log({
						type: 'change_password_error_impression',
						message: e.code,
					});
					resolve({ status: false });
					this.showError(e);
				});
		});
	}
}
