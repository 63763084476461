import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class TextBookService {
	constructor() {}

	open(moduleId) {
		const rUrl = window.location.href.split('#')[0];

		if (
			location.href.search('my-schedule') > 0 ||
			location.href.search('library') > 0
		) {
			window.open(
				`${environment.url.textbook}` +
					moduleId +
					'?r=' +
					rUrl +
					'#' +
					moduleId,
				'_blank',
			);
		} else {
			window.open(`${environment.url.textbook}` + moduleId, '_blank');
		}
	}
}
