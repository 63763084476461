import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot,
} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Observer } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'any',
})
export class AngularFireAuthGuardV2 implements CanActivate {
	userRole = '';

	userDetails: any = {};

	// write link : https://www.notion.so/terra-dot-do/74f62de34b134f4bb9315abcc72ac57a?v=7cf75499647a46698b2da9a099bb1b2a
	// https://terra-dot-do.notion.site/Redirection-logic-a8881edc4b0f4ff2ba90c92719eb9e1f?pvs=25
	// convert link : https://tableconvert.com/excel-to-json

	// 1 return true
	// 2 login page
	// 3 login page with currentUrl  // if visitor open direct libaray page
	// 4 add /app in URL
	// 5 remove /app in URL
	// 6 login page with currentUrl  // if visitor open direct libaray page with skip onboarding

	logicForRedirection = [
		{
			Page: '/learning',
			Visitor: '1',
			B2B: '4',
			'Non-Fellow': '4',
			Fellow: '4',
			RequireOnboarding: 'No',
			filtername: 'learning',
		},
		{
			Page: '/app/learning',
			Visitor: '5',
			B2B: '1',
			'Non-Fellow': '1',
			Fellow: '1',
			RequireOnboarding: 'No',
			filtername: 'learning',
		},
		{
			Page: '/app/orders',
			Visitor: '6',
			B2B: '1',
			'Non-Fellow': '1',
			Fellow: '1',
			RequireOnboarding: 'No',
			filtername: 'orders',
		},
		{
			Page: '/app/learning/:cohortId',
			Visitor: '/learning',
			B2B: '1',
			'Non-Fellow': '/app/learning',
			Fellow: '1',
			RequireOnboarding: 'No',
			filtername: 'learning',
		},
		{
			Page: '/app/learning/:cohortId/:Module',
			Visitor: '2',
			B2B: '1',
			'Non-Fellow': '/app/learning',
			Fellow: '1',
			RequireOnboarding: 'Yes',
			filtername: 'learning',
		},
		{
			Page: '/app/assignments/:assignmentsId',
			Visitor: '3',
			B2B: '1',
			'Non-Fellow': '/app/learning',
			Fellow: '1',
			RequireOnboarding: 'No',
			filtername: 'assignments',
		},
		{
			Page: '/app/learning/:assignmentsId/assignments/other',
			Visitor: '3',
			B2B: '1',
			'Non-Fellow': '/app/learning',
			Fellow: '1',
			RequireOnboarding: 'No',
			filtername: 'assignments',
		},
		{
			Page: '/app/learning/:cohortId/assignments/assignment-id/:assignmentId',
			Visitor: '3',
			B2B: '1',
			'Non-Fellow': '/app/learning',
			Fellow: '1',
			RequireOnboarding: 'No',
			filtername: 'assignments',
		},
		{
			Page: '',
			Visitor: '',
			B2B: '',
			'Non-Fellow': '',
			Fellow: '',
			RequireOnboarding: 'No',
			filtername: '',
		},
		{
			Page: '/events',
			Visitor: '/events/upcoming',
			B2B: '/app/events/upcoming',
			'Non-Fellow': '/app/events/upcoming',
			Fellow: '/app/events/upcoming',
			RequireOnboarding: 'No',
			filtername: 'events',
		},
		{
			Page: '/events/upcoming',
			Visitor: '1',
			B2B: '4',
			'Non-Fellow': '4',
			Fellow: '4',
			RequireOnboarding: 'No',
			filtername: 'events',
		},
		{
			Page: '/events/past',
			Visitor: '1',
			B2B: '4',
			'Non-Fellow': '4',
			Fellow: '4',
			RequireOnboarding: 'No',
			filtername: 'events',
		},
		{
			Page: '/events/:eventId',
			Visitor: '1',
			B2B: '4',
			'Non-Fellow': '4',
			Fellow: '4',
			RequireOnboarding: 'No',
			filtername: 'events',
		},
		{
			Page: '/app/events',
			Visitor: '/events/upcoming',
			B2B: '/app/events/upcoming',
			'Non-Fellow': '/app/events/upcoming',
			Fellow: '/app/events/upcoming',
			RequireOnboarding: 'No',
			filtername: 'events',
		},
		{
			Page: '/app/events/upcoming',
			Visitor: '5',
			B2B: '1',
			'Non-Fellow': '1',
			Fellow: '1',
			RequireOnboarding: 'No',
			filtername: 'events',
		},
		{
			Page: '/app/events/past',
			Visitor: '5',
			B2B: '1',
			'Non-Fellow': '1',
			Fellow: '1',
			RequireOnboarding: 'No',
			filtername: 'events',
		},
		{
			Page: '/app/events/:eventId',
			Visitor: '5',
			B2B: '1',
			'Non-Fellow': '1',
			Fellow: '1',
			RequireOnboarding: 'No',
			filtername: 'events',
		},
		{
			Page: '',
			Visitor: '',
			B2B: '',
			'Non-Fellow': '',
			Fellow: '',
			RequireOnboarding: 'No',
			filtername: '',
		},
		{
			Page: '/library',
			Visitor: '3',
			B2B: '/app/library',
			'Non-Fellow': '/app/learning',
			Fellow: '4',
			RequireOnboarding: 'No',
			filtername: 'library',
		},
		{
			Page: '/library/module/:moduleId',
			Visitor: '3',
			B2B: '/app/library/module/:moduleId',
			'Non-Fellow': '/app/learning',
			Fellow: '4',
			RequireOnboarding: 'No',
			filtername: 'library',
		},
		{
			Page: '/library/:search',
			Visitor: '3',
			B2B: '/app/library/:search',
			'Non-Fellow': '/app/learning',
			Fellow: '4',
			RequireOnboarding: 'No',
			filtername: 'library',
		},
		{
			Page: '/app/library',
			Visitor: '3',
			B2B: '1',
			'Non-Fellow': '/app/learning',
			Fellow: '1',
			RequireOnboarding: 'No',
			filtername: 'library',
		},
		{
			Page: '/app/library/module/:moduleId',
			Visitor: '3',
			B2B: '1',
			'Non-Fellow': '/app/learning',
			Fellow: '1',
			RequireOnboarding: 'No',
			filtername: 'library',
		},
		{
			Page: '/app/library/:search',
			Visitor: '3',
			B2B: '1',
			'Non-Fellow': '/app/learning',
			Fellow: '1',
			RequireOnboarding: 'No',
			filtername: 'library',
		},
		{
			Page: '',
			Visitor: '',
			B2B: '',
			'Non-Fellow': '',
			Fellow: '',
			RequireOnboarding: 'No',
			filtername: '',
		},
		{
			Page: '/profile',
			Visitor: '3',
			B2B: '/app/profile/view/me',
			'Non-Fellow': '/app/profile/view/me',
			Fellow: '4',
			RequireOnboarding: 'Yes',
			filtername: 'profile',
		},
		{
			Page: '/profile/experts',
			Visitor: '3',
			B2B: '/app/profile/view/me',
			'Non-Fellow': '/app/profile/view/me',
			Fellow: '4',
			RequireOnboarding: 'Yes',
			filtername: 'profile',
		},
		{
			Page: '/profile/people-directory',
			Visitor: '3',
			B2B: '/app/profile/view/me',
			'Non-Fellow': '/app/profile/view/me',
			Fellow: '4',
			RequireOnboarding: 'Yes',
			filtername: 'profile',
		},
		{
			Page: '/profile/view/me',
			Visitor: '3',
			B2B: '/app/profile/view/me',
			'Non-Fellow': '/app/profile/view/me',
			Fellow: '4',
			RequireOnboarding: 'Yes',
			filtername: 'profile',
		},
		{
			Page: '/app/profile',
			Visitor: '3',
			B2B: '/app/profile/view/me',
			'Non-Fellow': '/app/profile/view/me',
			Fellow: '1',
			RequireOnboarding: 'Yes',
			filtername: 'profile',
		},
		{
			Page: '/app/profile/experts',
			Visitor: '3',
			B2B: '/app/profile/view/me',
			'Non-Fellow': '/app/profile/view/me',
			Fellow: '1',
			RequireOnboarding: 'Yes',
			filtername: 'profile',
		},
		{
			Page: '/app/profile/people-directory',
			Visitor: '3',
			B2B: '/app/profile/view/me',
			'Non-Fellow': '/app/profile/view/me',
			Fellow: '1',
			RequireOnboarding: 'Yes',
			filtername: 'profile',
		},
		{
			Page: '/app/profile/view/me',
			Visitor: '3',
			B2B: '1',
			'Non-Fellow': '1',
			Fellow: '1',
			RequireOnboarding: 'Yes',
			filtername: 'profile',
		},
		{
			Page: '/profile/view/:profileId',
			Visitor: '3',
			B2B: '/app/profile/view/me',
			'Non-Fellow': '/app/profile/view/me',
			Fellow: '4',
			RequireOnboarding: 'Yes',
			filtername: 'profile',
		},
		{
			Page: '/app/profile/view/:profileId',
			Visitor: '3',
			B2B: '/app/profile/view/me',
			'Non-Fellow': '/app/profile/view/me',
			Fellow: '1',
			RequireOnboarding: 'Yes',
			filtername: 'profile',
		},
		{
			Page: '/app/news',
			Visitor: '3',
			B2B: '1',
			'Non-Fellow': '1',
			Fellow: '1',
			RequireOnboarding: 'Yes',
			filtername: 'news',
		},
		{
			Page: '/app/news/:news',
			Visitor: '3',
			B2B: '1',
			'Non-Fellow': '1',
			Fellow: '1',
			RequireOnboarding: 'Yes',
			filtername: 'news',
		},
		{
			Page: '',
			Visitor: '',
			B2B: '',
			'Non-Fellow': '',
			Fellow: '',
			RequireOnboarding: 'No',
			filtername: '',
		},
		{
			Page: '',
			Visitor: '',
			B2B: '',
			'Non-Fellow': '',
			Fellow: '',
			RequireOnboarding: 'No',
			filtername: '',
		},
		{
			Page: '/',
			Visitor: '2',
			B2B: '/app/learning',
			'Non-Fellow': '/app/learning',
			Fellow: '/app/learning',
			RequireOnboarding: 'No',
			filtername: '/',
		},
		{
			Page: '/app',
			Visitor: '2',
			B2B: '/app/learning',
			'Non-Fellow': '/app/learning',
			Fellow: '/app/learning',
			RequireOnboarding: 'No',
			filtername: '/app',
		},
		{
			Page: '',
			Visitor: '',
			B2B: '',
			'Non-Fellow': '',
			Fellow: '',
			RequireOnboarding: 'No',
			filtername: '',
		},
	];

	currentUrl = '';
	constructor(
		private auth: AngularFireAuth,
		private http: HttpClient,
		private router: Router,
		private cookieService: CookieService,
	) {}

	canActivate = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
		this.currentUrl = state.url;
		return new Observable(observer => {
			this.auth.user.pipe(take(1)).subscribe(async (user: any) => {
				if (!environment.welcomeRedirect) {
					if (!user) {
						this.userRole = 'Visitor';
						this.checkUserLinkValidation(observer);
					} else {
						this.loggedInUser(user, observer);
					}
				} else {
					if (!user) {
						const earth_token = this.cookieService.get('earth_token');
						const welcome_user = this.cookieService.get('welcome_user');

						if (earth_token) {
							if (earth_token && !welcome_user) {
								const url =
									environment.url.onboarding +
									'authorize' +
									'/?r=' +
									window.location.href;
								window.location.href = url;
							} else {
								this.auth
									.signInWithCustomToken(earth_token)
									.then(userObj => {
										this.auth.user
											.pipe(take(1))
											.subscribe(async (user: any) => {
												this.loggedInUser(user, observer);
											});
									})
									.catch(e => {
										if (
											e.code === 'auth/custom-token-mismatch' ||
											e.code === 'auth/invalid-custom-token'
										) {
											const url =
												environment.url.onboarding +
												'authorize' +
												'/?r=' +
												window.location.href;
											window.location.href = url;
										}
									});
							}
						} else {
							this.userRole = 'Visitor';
							this.checkUserLinkValidation(observer);
						}
					} else {
						const welcome_user = this.cookieService.get('welcome_user');

						if (user.uid !== welcome_user) {
							this.auth
								.signOut()
								.then(() => {
									const url =
										environment.url.onboarding +
										'authorize' +
										'/?r=' +
										window.location.href;
									window.location.href = url;
								})
								.catch((_a: any) => {
									const url =
										environment.url.onboarding +
										'authorize' +
										'/?r=' +
										window.location.href;
									window.location.href = url;
								});
						} else {
							this.loggedInUser(user, observer);
						}
					}
				}
			});
		}) as Observable<boolean>;
	};

	async loggedInUser(user, observer) {
		const token = await user.getIdToken();
		this.http
			.get<any>(environment.url.profile + '/user', {
				headers: {
					Authorization: 'Bearer ' + token,
				},
			})
			.subscribe(data => {
				this.userDetails = data;
				if (data.b2b_flag) {
					this.userRole = 'B2B';
					this.checkUserLinkValidation(observer);
				} else if (data.roles.find(role => role.id === 18)) {
					// this.userRole = 'Grow-User';
					this.userRole = 'Fellow';
					this.checkUserLinkValidation(observer);
				} else if (data.roles.find(role => role.id === 1)) {
					this.userRole = 'Fellow';
					this.checkUserLinkValidation(observer);
				} else if (data.roles.find(role => role.id === 3)) {
					this.userRole = 'Fellow';
					this.checkUserLinkValidation(observer);
				} else if (data.roles.find(role => role.id === 4)) {
					this.userRole = 'Fellow';
					this.checkUserLinkValidation(observer);
				} else {
					this.userRole = 'Non-Fellow';
					this.checkUserLinkValidation(observer);
				}
			});
	}

	checkUserLinkValidation(observer) {
		const currentUrl = this.currentUrl.split('#')[0].split('?')[0];
		let pageLogic = this.logicForRedirection.filter(
			({ Page, filtername }) => Page && currentUrl.indexOf(filtername) > -1,
		);

		let matchRoute = false;
		let matchRouteValue = {};

		const matchFix = pageLogic.filter(({ Page }) => Page === currentUrl);

		if (matchFix.length > 0) {
			matchRoute = true;
			matchRouteValue = matchFix[0];
		} else {
			for (let i = 0; i < pageLogic.length && !matchRoute; i++) {
				if (!matchRoute) {
					if (pageLogic[i].Page.indexOf(':') > -1) {
						if (
							pageLogic[i].Page.split('/').length ===
							currentUrl.split('/').length
						) {
							matchRoute = true;
							matchRouteValue = pageLogic[i];
						}
					}
				}
			}
		}

		if (matchRoute) {
			if (matchRouteValue[this.userRole] === '1') {
				if (
					matchRouteValue['RequireOnboarding'] === 'Yes' &&
					!this.userDetails.isOnboarded
				) {
					this.redirectToLogin();
					observer.next(false);
				} else {
					observer.next(true);
				}
			} else if (matchRouteValue[this.userRole] === '2') {
				window.location.href = environment.url.onboarding + '/on_boarding_done';
				observer.next(false);
			} else if (matchRouteValue[this.userRole] === '3') {
				this.redirectToLogin();
				observer.next(false);
			} else if (matchRouteValue[this.userRole] === '4') {
				if (this.currentUrl.indexOf('/app') > -1) {
					observer.next(true);
				} else {
					observer.next(this.router.parseUrl('/app' + this.currentUrl));
				}
			} else if (matchRouteValue[this.userRole] === '5') {
				observer.next(
					this.router.parseUrl(this.currentUrl.replace('/app', '')),
				);
			} else if (matchRouteValue[this.userRole] === '6') {
				window.location.href =
					environment.url.onboarding +
					'/on_boarding_done' +
					'/?r=' +
					window.location.href +
					'&s=true';
				observer.next(false);
			} else {
				observer.next(this.router.parseUrl(matchRouteValue[this.userRole]));
			}
		} else if (this.userRole === 'Visitor') {
			this.redirectToLogin();
			observer.next(false);
		} else if (!this.userDetails.isOnboarded) {
			this.redirectToLogin();
			observer.next(false);
		} else {
			observer.next(this.router.parseUrl('/app/learning'));
		}
	}

	redirectToLogin(url = window.location.href) {
		window.location.href =
			environment.url.onboarding + '/on_boarding_done' + '/?r=' + url;
	}
}
