/* eslint-disable no-underscore-dangle */
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PopoverController, ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import {
	ICalendarEvent,
	NgAddToCalendarService,
} from '@trademe/ng-add-to-calendar';
import firebase from 'firebase/compat/app';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { LearningService } from 'src/app/pages/app/learning/service/learning.service';
import { AuthService, RudderStack } from 'src/app/services';
import { ProfileService } from '../../../pages/app/profile/services/profile.service';
import { selectSidebarMenus } from '../../sidebar/redux/sidebar.selectors';

@Component({
	selector: 'app-rating-modal',
	templateUrl: './rating-modal.component.html',
	styleUrls: ['./rating-modal.component.scss'],
})
export class RatingModalComponent implements OnInit {
	rating: any[];
	@Input() shared: boolean = false;
	title = '';
	@Input() type: 'rating' | 'cal';
	@Input() eventData;
	@Input() cohortId;
	ratings: number[] = [1, 2, 3, 4, 5];
	selectedRating = 0;

	profile$: Observable<any> = this.profileService.loadProfileData();

	constructor(
		private popoverController: PopoverController,
		private _addToCalendarService: NgAddToCalendarService,
		private _sanitizer: DomSanitizer,
		private learningService: LearningService,
		private rudderStack: RudderStack,
		private authService: AuthService,
		private profileService: ProfileService,
		private store: Store,
		private modalController: ModalController,
	) {}

	ngOnInit() {
		if (this.eventData.type === 'class') {
			this.title = 'How did you like this class?';
			this.rudderStack.log({
				type: 'feedback_class_click',
				cohortId: this.cohortId,
				classId: this.eventData.classId,
			});
		} else if (this.eventData.type === 'assignments') {
			this.title = 'How did you like this assignment?';
			this.rudderStack.log({
				type: 'feedback_assignment_click',
				cohortId: this.cohortId,
				assignmentId: this.eventData.id,
			});
		} else {
			this.title = 'How did you like this event?';
			this.rudderStack.log({
				type: 'feedback_event_click',
				cohortId: this.cohortId,
				eventId: this.eventData.eventId,
			});
		}
		this.rating = [false, false, false, false, false];
	}

	onClose() {
		this.popoverController.dismiss();
	}

	getRatingSvgPath(rating: number): string {
		return `assets/rating_${rating}.svg`; // Adjust the path and file names accordingly
	}

	onEmojiClick(index) {
		this.selectedRating = index;
		for (let i = 0; i < 5; i++) {
			this.rating[i] = index >= i ? true : false;
		}
		//return;
		let id = this.eventData.eventId;
		if (this.eventData.type === 'assignments') {
			id = this.eventData.id;
		}
		if (this.eventData.type === 'class') {
			this.rudderStack.log({
				type: 'feedback_class_submit',
				cohortId: this.cohortId,
				classId: this.eventData.classId,
				score: this.selectedRating,
			});
		} else if (this.eventData.type === 'assignments') {
			this.rudderStack.log({
				type: 'feedback_assignment_submit',
				cohortId: this.cohortId,
				assignmentId: this.eventData.id,
				name: this.eventData.name,
			});
		} else {
			this.rudderStack.log({
				type: 'feedback_event_submit',
				cohortId: this.cohortId,
				eventId: this.eventData.eventId,
				name: this.eventData.name,
				score: this.selectedRating,
			});
		}

		this.learningService
			.rateCard(
				this.cohortId,
				this.eventData.eventId || this.eventData.id,
				this.eventData.type,
				this.selectedRating,
				'',
			)
			.subscribe((data: any) => {
				if (data.message?.includes('success')) {
					if (this.eventData.type === 'class') {
						this.learningService
							.loadModulesData(this.cohortId)
							.pipe(first())
							.subscribe(({ data }: any) => {
								this.learningService.loadModules(
									this.cohortId,
									data.map(records => {
										return {
											...records,
											classes: records.classes.map(record => {
												if (record.id === this.eventData.id) {
													return {
														...record,
														feedbackAdded: true,
														typeformSubmitted: true,
													};
												} else {
													return record;
												}
											}),
										};
									}),
								);

								this.authService.addToastMsg('Feedback successfully submitted');

								this.shared = true;
								this.title = 'Thanks for sharing!';
							});
					} else if (this.eventData.type === 'assignments') {
						this.learningService
							.loadAssignmentsData(this.cohortId)
							.pipe(first())
							.subscribe(({ data }: any) => {
								this.learningService.loadAssignments(
									this.cohortId,
									data.map(record => {
										if (record.id === this.eventData.id) {
											return {
												...record,
												feedbackAdded: true,
												typeformSubmitted: true,
											};
										} else {
											return record;
										}
									}),
								);

								this.authService.addToastMsg('Feedback successfully submitted');

								this.shared = true;
								this.title = 'Thanks for sharing!';
							});
					} else {
						this.learningService
							.loadClassAndEventsData(this.cohortId)
							.pipe(first())
							.subscribe(({ data }: any) => {
								this.learningService.loadEvents(
									this.cohortId,
									data.map(record => {
										if (record.id === this.eventData.id) {
											return {
												...record,
												feedbackAdded: true,
												typeformSubmitted: true,
											};
										} else {
											return record;
										}
									}),
								);

								this.authService.addToastMsg('Feedback successfully submitted');

								this.shared = true;
								this.title = 'Thanks for sharing!';
							});
					}
				}
			});

		// setTimeout(() => {
		// 	this.shared = true;
		// 	this.title = 'Thanks for sharing!';
		// }, 500);
	}
	async closePopOver() {
		await this.modalController.dismiss();
	}

	onCal(str) {
		const newEvent: ICalendarEvent = {
			// Event title
			title: this.eventData.name,
			// Event start date
			start: new Date(this.eventData.startDateTime),
			// Event duration (IN MINUTES)
			duration: 120,
			// If an end time is set, this will take precedence over duration (optional)
			end: new Date(this.eventData.endDateTime),
			// Event Description (optional)
			description: this.eventData.description,

			address: this.eventData.address,
		};
		let getHrefFor;
		if (str === 'apple') {
			getHrefFor = this._addToCalendarService.calendarType.iCalendar;
		}
		if (str === 'google') {
			getHrefFor = this._addToCalendarService.calendarType.google;
		}

		const URL = this._addToCalendarService.getHrefFor(getHrefFor, newEvent);
		const sanitizeUrl: any = this._sanitizer.bypassSecurityTrustUrl(URL);

		window.open(sanitizeUrl.changingThisBreaksApplicationSecurity, '_blank');
	}

	shareMore() {
		const type = this.eventData.type;
		let url = ``;

		if (this.eventData.type === 'class') {
			this.rudderStack.log({
				type: 'feedback_class_shareMore_click',
				cohortId: this.cohortId,
				classId: this.eventData.classId,
			});
		} else if (this.eventData.type === 'assignments') {
			this.rudderStack.log({
				type: 'feedback_assignment_shareMore_click',
				cohortId: this.cohortId,
				assignmentId: this.eventData.id,
				name: this.eventData.name,
			});
		} else {
			this.rudderStack.log({
				type: 'feedback_event_shareMore_click',
				cohortId: this.cohortId,
				eventId: this.eventData.eventId,
				name: this.eventData.name,
			});
		}

		this.store
			.select(selectSidebarMenus)
			.pipe(first())
			.subscribe(({ studentId }) => {
				this.profile$.pipe(first()).subscribe(({ data }) => {
					let typeformStr = `&cohort=${this.cohortId}&stdid=${studentId}&name=${this.eventData.name}&first_name=${data.firstName}&last_name=${data.lastName}&email_id=${data.email}`;
					if (type === 'class') {
						url = `https://atisundar.typeform.com/class-feedback#class=${this.eventData.eventId}${typeformStr}`;
					} else if (type === 'webinar') {
						url = `https://atisundar.typeform.com/events-feedback#event_id=${this.eventData.eventId}${typeformStr}`;
					} else if (type === 'assignments') {
						url = `https://atisundar.typeform.com/assignment#assignment_id=${this.eventData.id}${typeformStr}`;
					}
					window.open(url, '_blank');
				});
			});
	}
}
